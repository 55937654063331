.hover {
    animation: hoverUpDown 3s ease-in-out infinite;
}

@keyframes hoverUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(0);
    }
    75% {
        transform: translateY(-10px);
    }
}